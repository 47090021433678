import PropTypes from 'prop-types';
import React from 'react';

import './link-list.scss';

const LinkList = ({ context, links }) => (
  <ul className={`linkList${context ? ` linkList--${context}` : ''}`}>
    {links.map(link => (
      <li key={link.id}>
        {link.url.indexOf('mailto') ? <a href={link.url} target="_blank" rel="noopener noreferrer">{link.label}</a>
          : <a href={link.url}>{link.label}</a>
        }
      </li>
    ))}
  </ul>
);

LinkList.propTypes = {
  context: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
};

LinkList.defaultProps = {
  context: '',
  links: [],
};

export default LinkList;
