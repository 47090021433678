/* eslint-disable camelcase */
import getDataProduct from './get-data-product';

export default function getDataCategory(data = {}) {
  const {
    contentful_id,
    products = [],
    name,
  } = data;

  return {
    contentfulId: contentful_id,
    items: (products || []).map(getDataProduct),
    name,
  };
}
