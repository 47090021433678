import PropTypes from 'prop-types';
import React from 'react';
import { isTouch, noop } from '../../helpers';
import { imagePropTypes } from '../image/image';
import Picture from '../picture/picture';

import './expanding-images.scss';

class ExpandingImages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
    };
  }

  expandImage(index) {
    this.setState({
      currentImage: index,
    });
  }

  render() {
    const { currentImage } = this.state;
    const { images } = this.props;
    const count = images.length;

    return (
      <div className="expandingImages">
        {images.map((image, index) => (
          <div
            className={`expandingImage${index === currentImage ? ' expandingImage--current' : ''}`}
            key={image.id}
            onMouseEnter={isTouch() ? noop : this.expandImage.bind(this, index)}
          >
            <div className="expandingImage__wrap">
              <Picture image={image} />
              {image.text && <div className="expandingImage__title">{image.text}</div>}
            </div>
            <span className="expandingImage__no">
              {index + 1}
              /
              {count}
            </span>
            {index !== currentImage && <button type="button" onClick={this.expandImage.bind(this, index)}>Expand</button>}
          </div>
        ))}
      </div>
    );
  }
}

ExpandingImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
    ...imagePropTypes,
  })).isRequired,
};

export default ExpandingImages;
