import PropTypes from 'prop-types';
import React from 'react';
import Image from './image';
import { imagePropTypes } from '../../prop-types/image';

import './images.scss';

const layoutMap = {
  layout8cols: ['cols8'],
  layout6cols6cols: ['cols6', 'cols6'],
  layout8cols4cols: ['cols8', 'cols4'],
  layout4cols8cols: ['cols4', 'cols8'],
  layout4cols4cols4cols: ['cols4', 'cols4', 'cols4'],
};

const Images = ({
  images,
  layout,
  verticalAlign,
}) => {
  const layoutMapKey = `layout${layout.replace(/-/g, '')}`;
  const cols = {}.hasOwnProperty.call(layoutMap, layoutMapKey)
    ? layoutMap[layoutMapKey] : layoutMap.layout8cols;
  const vAlign = ['top', 'bottom'].indexOf(verticalAlign) > -1 ? verticalAlign : 'top';
  return (
    <div className="images">
      {images.map((item, index) => {
        const mapIndex = (cols.length + index) % cols.length;
        return (
          <Image layoutCols={cols[mapIndex]} verticalAlign={vAlign} {...item} />
        );
      })}
    </div>
  );
};

Images.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(imagePropTypes)),
  layout: PropTypes.string,
  verticalAlign: PropTypes.string,
};

Images.defaultProps = {
  images: [],
  layout: '8-cols',
  verticalAlign: 'top',
};

export default Images;
