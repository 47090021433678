import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

import './btns-next.scss';

const BtnNext = ({ buttons, narrow }) => (
  <ul className={`btnsNext${narrow ? ' btnsNext--narrow' : ''}`}>
    {buttons.map(({ link, text }) => (
      <li key={link}>
        <Link to={link} className={`btnNext ${narrow ? 'btnNext--narrow' : ''}`}>
          {text}
        </Link>
      </li>
    ))}
  </ul>
);

BtnNext.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  narrow: PropTypes.bool,
};

BtnNext.defaultProps = {
  narrow: false,
};

export default BtnNext;
