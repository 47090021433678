export default function getDataExhibitionLink({ node }) {
  const {
    contentful_id, // eslint-disable-line camelcase
    dateFrom,
    dateTo,
    id,
    slug,
    title,
  } = node;
  return {
    contentfulId: contentful_id,
    dateEnd: dateTo,
    dateStart: dateFrom,
    id,
    place: title,
    url: slug,
  };
}
