import PropTypes from 'prop-types';
import React from 'react';
import ExhibitionLink, { propTypes as exhibitionLinkPropTypes } from './exhibition-link';

import './exhibition-links.scss';

const ExhibitionLinks = ({
  exhibitions,
  title,
}) => (
  <div className="exhibitions">
    <div className="exhibitions__title">
      {title}
    </div>
    <ul className="exhibitions__list">
      {exhibitions.map(exhibition => (
        <ExhibitionLink key={exhibition.id} {...exhibition} />
      ))}
    </ul>
  </div>
);

ExhibitionLinks.propTypes = {
  exhibitions: PropTypes.arrayOf(PropTypes.shape(exhibitionLinkPropTypes)).isRequired,
  title: PropTypes.string.isRequired,
};

export default ExhibitionLinks;
