import get from 'lodash.get';

export default function getDataNewsTiles(data = {}) {
  return data.map(({ node }) => {
    const {
      date,
      id,
      image,
      mapLink,
      moreInformationLink,
      slug,
      title,
    } = node;

    const height = get(image, 'file.details.image.height', 1);
    const width = get(image, 'file.details.image.width', 1);

    return {
      copy: get(node, 'copy.copy', ''),
      date,
      fullscreen: true,
      id,
      image: {
        id: get(image, 'id', ''),
        height,
        src: get(image, 'file.url', ''),
        width,
        aspectRatio: width / height,
      },
      imageTitle: get(node, 'image.description', ''),
      mapLink,
      moreInformationLink,
      slug,
      title,
    };
  });
}
