import get from 'lodash.get';

const mapImage = function mapImageData(image) {
  const {
    contentful_id, // eslint-disable-line camelcase
    description = '',
    file,
    id,
    title = '',
  } = image;

  const width = get(file, 'details.image.width', 1);
  const height = get(file, 'details.image.height', 1);

  return {
    alt: title,
    contentfulId: contentful_id,
    description,
    height,
    id,
    src: get(file, 'url', ''),
    width,
    aspectRatio: width / height,
  };
};

export default function getDataHero(data = {}) {
  const {
    contentful_id = '', // eslint-disable-line camelcase
    slideshow = [],
    slug = '',
  } = data;

  return {
    contentfulId: contentful_id,
    slideshowCaption: get(data, 'slideshowCaption.slideshowCaption', ''),
    slug,
    type: 'slideshow--case-study',
    items: slideshow.map(mapImage),
  };
}
