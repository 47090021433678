import get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import PressLink from './press-link';
import './press-list.scss';

import LoadMore from '../load-more/load-more';
import { contentful } from '../../utilities';

const groupByYears = function groupArticlesByYears(items = []) {
  items.sort((a, b) => {
    if (a.date === b.date) return 0;
    if (a.date < b.date) return 1;
    return -1;
  });
  return Array.from(new Set(items.map(item => item.date.substr(0, 4))))
    .map(year => items.filter(item => item.date.substr(0, 4) === year));
};

const formatPressItems = function formatPressItemsFromContentfulApi(items) {
  return items.map(item => ({
    contentful_id: item.sys.id,
    date: get(item, 'fields.date', ''),
    id: item.sys.id,
    link: get(item, 'fields.link', ''),
    magazineTitle: get(item, 'fields.magazineTitle', ''),
    title: get(item, 'fields.title', ''),
  }));
};

class PressList extends Component {
  constructor(props) {
    super(props);
    const {
      items = [],
      total,
    } = this.props;

    this.items = items;
    this.limit = items.length;
    this.total = total;
    this.state = {
      canLoadMore: total > items.length,
      groups: groupByYears(items),
    };

    this.loadMore = this.loadMore.bind(this);
    this.processNewItems = this.processNewItems.bind(this);
  }

  loadMore(ev) {
    ev.preventDefault();
    contentful.loadPress(this.items.length, this.limit)
      .then(this.processNewItems);
  }

  processNewItems(newItems) {
    this.items = [
      ...this.items,
      ...formatPressItems(newItems),
    ];
    this.setState({
      canLoadMore: this.total > this.items.length,
      groups: groupByYears(this.items),
    });
  }

  render() {
    const {
      groups = [],
      canLoadMore,
    } = this.state;

    return (
      <Fragment>
        <div className="press__title">Press</div>
        <div className="press">
          <ul className="press__list">
            {groups.map(group => group.map((item, index) => (
              <PressLink key={item.id} index={index} {...item} />
            )))}
          </ul>
          {canLoadMore && (<LoadMore text="Load More Press" loadMore={this.loadMore} />)}
        </div>
      </Fragment>
    );
  }
}

PressList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number,
};

PressList.defaultProps = {
  total: 0,
};

export default PressList;
