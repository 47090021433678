import PropTypes from 'prop-types';
import React from 'react';

import './product-specs.scss';

const ProductSpecs = ({ contact, specification }) => (
  <div className="productSpecs">
    <dl className="productSpecs__items">
      {specification.map(({ description, title }) => (
        <React.Fragment key={title}>
          <dt key={`${title}-term`}>
            {title}
          </dt>
          <dd key={`${title}-desc`}>
            {description}
          </dd>
        </React.Fragment>
      ))}
    </dl>
    <dl className="productSpecs__info">
      {contact.email ? (
        <React.Fragment>
          <dt>Enquire</dt>
          <dd><a href={`mailto:${contact.email}`}>EMAIL</a></dd>
        </React.Fragment>
      ) : ''}
      {contact.file ? (
        <React.Fragment>
          <dt>Technical page</dt>
          <dd><a href={contact.file} target="_blank" rel="noopener noreferrer">{contact.format}</a></dd>
        </React.Fragment>
      ) : ''}
    </dl>
  </div>
);

ProductSpecs.propTypes = {
  contact: PropTypes.shape({
    email: PropTypes.string,
    file: PropTypes.string,
    format: PropTypes.string,
  }),
  specification: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
};

ProductSpecs.defaultProps = {
  contact: '',
};

export default ProductSpecs;
