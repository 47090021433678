import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { BREAKPOINT_MOBILE } from '../../config';
import { imagePropTypes } from '../image/image';
import { isClient } from '../../helpers';
import Picture from '../picture/picture';
import { session } from '../../utilities';

import './hero.scss';

class Hero extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
      scrollY: -1,
      isMobile: false,
      showing: true,
    };
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  componentDidMount() {
    const {
      displayInline,
      displayOnce,
    } = this.props;

    if (isClient()) {
      window.addEventListener('resize', this.onWindowResize);
      this.onWindowResize();

      if ((!session.wasPathVisited() || !displayOnce) && !displayInline && !window.location.hash) {
        window.addEventListener('scroll', this.onScroll);
        this.setState({
          enabled: true,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('scroll', this.onScroll);
  }

  onCloseClick() {
    this.close();
  }

  onScroll() {
    const { scrollY } = this.state;

    // Capture the initial value
    if (scrollY === -1) {
      this.setState({
        scrollY: window.scrollY,
      });
    }

    // Allow for 25 px scroll
    if (window.scrollY - scrollY > 25) {
      this.close();
      window.removeEventListener('scroll', this.onScroll);
    }
  }

  onWindowResize() {
    const newIsMobile = window.innerWidth < BREAKPOINT_MOBILE;
    const { isMobile } = this.state;
    if ((!isMobile && newIsMobile) || (isMobile && !newIsMobile)) {
      this.setState({
        isMobile: newIsMobile,
      });
    }
  }

  close() {
    const { showing } = this.state;
    if (!showing) {
      return;
    }

    this.setState({
      showing: false,
    });
  }

  render() {
    const {
      enabled,
      isMobile,
      showing,
    } = this.state;

    const {
      displayInline,
      displayOnce,
      image,
      isFirst,
      title,
    } = this.props;

    if (!enabled) {
      return null;
    }

    const heroProps = (isFirst && (isMobile || (!isMobile && !displayInline)))
      ? {
        className: [
          'hero',
          showing ? 'hero--showing' : '',
          displayOnce ? 'hero--intro' : '',
        ].join(' '),
      }
      : {
        className: [
          'hero',
          'hero--inline',
          isFirst ? 'hero--first' : '',
        ].join(' '),
      };

    return (
      <div {...heroProps}>
        {title && (
          <h1 className="hero__title">
            <ReactMarkdown
              allowedTypes={['emphasis', 'text']}
              source={title}
              unwrapDisallowed
            />
          </h1>
        )}
        {image.src && (<Picture image={image} />)}
        <button type="button" className="hero__button" onClick={this.onCloseClick}>
          <span>Show page</span>
        </button>
      </div>
    );
  }
}

Hero.propTypes = {
  displayInline: PropTypes.bool,
  displayOnce: PropTypes.bool,
  image: PropTypes.shape(imagePropTypes),
  isFirst: PropTypes.bool,
  title: PropTypes.string,
};

Hero.defaultProps = {
  displayInline: false,
  displayOnce: false,
  image: {},
  isFirst: false,
  title: '',
};

export default Hero;
