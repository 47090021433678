import PropTypes from 'prop-types';
import React from 'react';

import { navigate } from '@reach/router';

import './overlay.scss';

class Overlay extends React.Component {
  constructor(props) {
    super(props);

    this.onCloseClick = this.onCloseClick.bind(this);
  }

  onCloseClick(ev) {
    const {
      closeCallback,
      inline,
      locationHistory,
    } = this.props;
    ev.preventDefault();

    if (inline) {
      closeCallback();
      return;
    }

    if (locationHistory.length > 2) {
      window.history.back();
      return;
    }

    if (locationHistory.length === 2) {
      navigate(locationHistory[0]);
      return;
    }

    navigate(ev.currentTarget.getAttribute('href'));
  }

  getClasses() {
    const {
      brown,
      fullScreen,
      inline,
      isOpen,
    } = this.props;

    const classes = ['overlay'];
    if (fullScreen) {
      classes.push('overlay--fullscreen');
    }

    if (inline) {
      classes.push('overlay--inline');

      if (isOpen) {
        classes.push('is-open');
      }
    }

    if (brown) {
      classes.push('overlay--brown');
    }

    return classes.join(' ');
  }

  render() {
    const {
      children,
    } = this.props;

    return (
      <div className={this.getClasses()}>
        <div className="overlay__pane">
          <div className="overlay__pane__inner">
            {children}
          </div>
        </div>
        <a href="/" className="overlay__close" onClick={this.onCloseClick}>
          <span className="visually-hidden">Close Modal</span>
        </a>
      </div>
    );
  }
}

Overlay.propTypes = {
  brown: PropTypes.bool,
  children: PropTypes.node.isRequired,
  closeCallback: PropTypes.func,
  fullScreen: PropTypes.bool,
  inline: PropTypes.bool,
  isOpen: PropTypes.bool,
  locationHistory: PropTypes.arrayOf(PropTypes.string),
};

Overlay.defaultProps = {
  brown: false,
  closeCallback: () => null,
  fullScreen: false,
  inline: false,
  isOpen: false,
  locationHistory: [],
};

export default Overlay;
