import get from 'lodash.get';

export default function getDataVideo(data = {}) {
  const {
    contentful_id, // eslint-disable-line camelcase
    coverImage,
    id,
    videoFile,
    videoUrl,
  } = data;
  return {
    caption: get(data, 'caption', ''),
    contentfulId: contentful_id,
    id,
    image: {
      alt: get(data, 'caption', ''),
      contentfulId: coverImage.contentful_id,
      height: get(coverImage, 'file.details.image.height', 1600),
      id: coverImage.id,
      src: get(coverImage, 'file.url', ''),
      width: get(coverImage, 'file.details.image.width', 900),
    },
    videoUrl: videoFile ? get(videoFile, 'file.url', videoUrl) : videoUrl,
  };
}
