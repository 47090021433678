import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Awards from '../awards/awards';
import BtnsNext from '../btns-next/btns-next';
import Category from '../category/category';
import DownloadsAndContact from '../downloads-contact/downloads-contact';
import Hero from '../hero/hero';
import Images from '../image/images';
import LayoutContextConsumer from '../layout/layout-context-consumer';
import Lead from '../lead/lead';
import NavTarget from '../side-nav/nav-target';
import NewsTiles from '../news-tile/news-tiles';
import Overlay from '../overlay/overlay';
import Paragraph from '../paragraph/paragraph';
import PressList from '../press/press-list';
import SideNav from '../side-nav/side-nav';
import Tiles from '../tiles/tiles';
import Video from '../video/video';

import {
  getDataAwardsList,
  getDataCategory,
  getDataDownloads,
  getDataHero,
  getDataImages,
  getDataLink,
  getDataNavTarget,
  getDataNewsTiles,
  getDataPressList,
  getDataText,
  getDataTiles,
  getDataVideo,
} from '../../proxies';

const getTextModuleComponent = function getTextModuleComponentType(data, key) {
  const {
    type = '',
  } = data;

  switch (type) {
    case 'Downloads':
      return (<DownloadsAndContact key={key} {...getDataDownloads(data)} />);
    case 'Lead':
      return (<Lead key={key} {...getDataText(data)} />);
    case 'Legal':
      return (<Paragraph key={key} typeClass="legal" {...getDataText(data)} />);
    case 'Table':
      return (<Paragraph key={key} typeClass="table" {...getDataText(data)} />);
    case 'Contact':
      return (<Paragraph key={key} typeClass="contact" {...getDataText(data)} />);
    default:
      return (<Paragraph key={key} {...getDataText(data)} />);
  }
};

const groupConsecutiveLinks = function groupConsecutiveLinksModules(current, index, modules) {
  // catch ContentfulLink modules directly following this one and move them into buttons array
  // bail out on any other module type

  const buttons = [current];
  let whileIndex = index + 1;

  while (whileIndex < modules.length
    && modules[whileIndex]['__typename'] === 'ContentfulLink' // eslint-disable-line dot-notation
  ) {
    // copy module to buttons array
    buttons.push({ ...modules[whileIndex] });

    // remove it from the source array to prevent duplication
    // beware of the mutation here 😱
    modules[whileIndex] = null; // eslint-disable-line no-param-reassign

    whileIndex += 1;
  }

  return buttons;
};

const processModules = function processContentModules(contentModules) {
  // change modules structure by grouping together
  // cnsecutive ContentfulLink modules into an array

  // make a copy of modules array so it can safely mutate
  return [...contentModules].reduce((accumulator, current, index, modules) => {
    if (!current) {
      // skip empty mutaded cells
      return accumulator;
    }

    // eslint-disable-next-line dot-notation
    if (current['__typename'] === 'ContentfulLink') {
      // check for links ahead and push grouped data
      accumulator.push({
        __typename: 'ContentfulLink',
        buttons: groupConsecutiveLinks(current, index, modules),
        type: current.type,
      });
    } else {
      // just pass current module
      accumulator.push(current);
    }

    return accumulator;
  }, []);
};

class Page extends Component {
  constructor(props) {
    super(props);

    this.getNavTargetComponent = this.getNavTargetComponent.bind(this);
    this.getSideNavLinks = this.getSideNavLinks.bind(this);
    this.hasInlineHero = this.hasInlineHero.bind(this);
    this.isFirstHero = this.isFirstHero.bind(this);
    this.renderModule = this.renderModule.bind(this);
  }

  getNavTargetComponent(data, key) {
    const {
      collectionAwards,
      collectionNews,
      collectionPress,
    } = this.props;
    const { entityCollection } = data;
    const navTargetComponent = (<NavTarget key={key} {...getDataNavTarget(data)} />);

    if (!(entityCollection && collectionAwards)) {
      return navTargetComponent;
    }

    let entityCollectionComponent;
    switch (entityCollection.toLowerCase()) {
      case 'awards':
        entityCollectionComponent = (<Awards key={`${key}-awards`} awards={getDataAwardsList(collectionAwards.items)} total={collectionAwards.total} />);
        break;
      case 'news':
        entityCollectionComponent = (<NewsTiles key={`${key}-news`} posts={getDataNewsTiles(collectionNews.items)} total={collectionNews.total} />);
        break;
      case 'press':
        entityCollectionComponent = (<PressList key={`${key}-press`} items={getDataPressList(collectionPress.items)} total={collectionPress.total} />);
        break;
      default:
        entityCollectionComponent = '';
    }

    return [
      navTargetComponent,
      entityCollectionComponent,
    ];
  }

  getSideNavLinks() {
    const {
      contentModules = [],
    } = this.props;

    return contentModules.filter((data) => {
      const {
        __typename = '',
      } = data;
      return __typename === 'ContentfulPageSection';
    }).map(getDataNavTarget);
  }

  isFirstHero(index) {
    const {
      contentModules = [],
    } = this.props;
    return !contentModules.some((module, moduleIndex) => {
      const {
        __typename = '',
      } = module;
      return moduleIndex < index && __typename !== 'ContentfulPageSection';
    });
  }

  hasInlineHero() {
    const { isFirstHero } = this;
    const {
      contentModules = [],
    } = this.props;
    const index = contentModules.findIndex((module) => {
      const {
        __typename = '',
      } = module;
      return __typename === 'ContentfulHero';
    });

    if (index > -1 && isFirstHero(index, contentModules)) {
      return !!contentModules[index].displayInline;
    }

    return false;
  }

  renderModule(data = {}, index) {
    const {
      getNavTargetComponent,
      isFirstHero,
    } = this;
    const {
      __typename = '',
      id = '',
    } = data;
    const key = id + index;

    switch (__typename) {
      case 'ContentfulHero':
        return (<Hero key={key} isFirst={isFirstHero(index)} {...getDataHero(data)} />);
      case 'ContentfulCollectionTiles':
        return (<Tiles key={key} {...getDataTiles(data)} />);
      case 'ContentfulText':
        return getTextModuleComponent(data, key);
      case 'ContentfulImages':
        return (<Images key={key} {...getDataImages(data)} />);
      case 'ContentfulLink':
        return (<BtnsNext key={key} {...getDataLink(data)} />);
      case 'ContentfulPageSection':
        return getNavTargetComponent(data, key);
      case 'ContentfulProductCollection':
        return (<Category key={key} {...getDataCategory(data)} />);
      case 'ContentfulModuleVideo':
        return (<Video key={key} {...getDataVideo(data)} />);
      default:
        return (<Fragment key={key} />);
    }
  }

  render() {
    const {
      getSideNavLinks,
      hasInlineHero,
      renderModule,
    } = this;
    const {
      displayInOverlay = false,
      contentModules = [],
    } = this.props;

    const links = getSideNavLinks();

    const linksEl = links.length ? (
      <SideNav
        links={getSideNavLinks()}
        withInlineHero={hasInlineHero()}
      />
    ) : <React.Fragment />;

    if (displayInOverlay) {
      return (
        <Overlay>
          <LayoutContextConsumer>
            {linksEl}
            {processModules(contentModules).map(renderModule)}
          </LayoutContextConsumer>
        </Overlay>
      );
    }

    return (
      <LayoutContextConsumer>
        {linksEl}
        {processModules(contentModules).map(renderModule)}
      </LayoutContextConsumer>
    );
  }
}

const collectionProps = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  total: PropTypes.number,
};

Page.propTypes = {
  collectionAwards: PropTypes.shape(collectionProps),
  collectionNews: PropTypes.shape(collectionProps),
  collectionPress: PropTypes.shape(collectionProps),
  contentModules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayInOverlay: PropTypes.bool,
};

Page.defaultProps = {
  collectionAwards: {
    items: [],
    total: 0,
  },
  collectionNews: {
    items: [],
    total: 0,
  },
  collectionPress: {
    items: [],
    total: 0,
  },
  displayInOverlay: false,
};

export default Page;
