import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import './lead.scss';

const Lead = ({ small, content }) => (
  <p className={`lead ${small ? 'lead--small' : ''}`}>
    <ReactMarkdown
      allowedTypes={['emphasis', 'text', 'break']}
      source={content}
      unwrapDisallowed
    />
  </p>
);

Lead.propTypes = {
  content: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

Lead.defaultProps = {
  small: false,
};

export default Lead;
