import PropTypes from 'prop-types';
import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { imagePropTypes } from '../image/image';
import { propTypes as tilePropTypes } from '../tiles/tile';

import Lead from '../lead/lead';
import LinkList from '../link-list/link-list';
import Overlay from '../overlay/overlay';
import Paragraph from '../paragraph/paragraph';
import Picture from '../picture/picture';
import Slideshow from '../slideshow/slideshow';
import Tiles from '../tiles/tiles';

import './exhibition.scss';

const { GATSBY_BASE_URL } = process.env;

const getLinks = function getExhibitionSideLinks({
  contentfulId,
  id,
  mapLink,
  moreInformationLink,
  slug,
  title,
}) {
  const url = `${GATSBY_BASE_URL}exhibitions/${slug}`;
  const links = [];
  if (mapLink) {
    links.push({
      id: `link-map-${id}`,
      contentfulId: `link-map-${contentfulId}`,
      label: 'View Map',
      url: mapLink,
    });
  }
  if (moreInformationLink) {
    links.push({
      id: `link-more-${id}`,
      contentfulId: `link-more-${contentfulId}`,
      label: 'View More Information',
      url: moreInformationLink,
    });
  }
  return [
    ...links,
    {
      id: `link-mailto-${id}`,
      contentfulId: `link-mailto-${contentfulId}`,
      label: 'Share: Mail',
      url: `mailto:?subject=${encodeURIComponent(`DZEK - ${title}`)}&body=${encodeURIComponent(url)}`,
    },
    {
      id: `link-twitter-${id}`,
      contentfulId: `link-twitter-${contentfulId}`,
      label: 'Share: Twitter',
      url: `http://www.twitter.com/share?text=${encodeURIComponent(title)};url=${encodeURIComponent(url)}`,
    },
    {
      id: `link-fb-${id}`,
      contentfulId: `link-fb-${contentfulId}`,
      label: 'Share: Facebook',
      url: `http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    },
  ];
};

class Exhibition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverlayOpen: false,
    };

    this.onOverlayToggle = this.onOverlayToggle.bind(this);
  }

  onOverlayToggle() {
    const { isOverlayOpen } = this.state;
    this.setState({ isOverlayOpen: !isOverlayOpen });
  }

  render() {
    const { onOverlayToggle } = this;
    const { content } = this.props;
    const {
      copy,
      endDate,
      imagesSlider,
      lead,
      startDate,
      tiles,
      title,
    } = content;
    const { isOverlayOpen } = this.state;

    const endDay = dateFormat(endDate, 'd');
    const endMonth = dateFormat(endDate, 'mmm');
    // const endYear = dateFormat(endDate, 'yyyy');
    const startDay = dateFormat(startDate, 'd');
    const startMonth = dateFormat(startDate, 'mmm');
    const startYear = dateFormat(startDate, 'yyyy');
    const sliderCover = [...imagesSlider].shift();

    return (
      <article className="exhibitionContent">
        <div className="exhibitionContent__heading">
          <h1 className="exhibitionContent__title">
            {startYear}
            &nbsp;
            {startDay}
            &nbsp;
            {startMonth}
            &nbsp;
            to
            &nbsp;
            {endDay}
            &nbsp;
            {endMonth}
            <br />
            {title}
          </h1>
        </div>
        <div className="exhibitionContent__content">
          <div className="exhibitionContent__images">
            {sliderCover && (
              <div className="exhibitionContent__image__container" key={sliderCover.id}>
                <button type="button" onClick={onOverlayToggle}>
                  <div
                    className="exhibitionContent__image__wrap"
                    style={{ paddingTop: `${(sliderCover.height / sliderCover.width) * 100}%` }}
                  >
                    <Picture className="exhibitionContent__image" image={sliderCover} cols={8} />
                  </div>
                  <div className="exhibitionContent__image__wrap__desc">
                    {!(imagesSlider.length > 1) || `1/${imagesSlider.length}  `}
                    {sliderCover.description}
                  </div>
                </button>
              </div>
            )}
          </div>
          <div className="exhibitionContent__text">
            {lead && <Lead content={lead} />}
            <Paragraph
              content={copy}
              indent
            />

            <LinkList context="exhibition" links={getLinks(content)} />
          </div>
          <Tiles {...tiles} />
        </div>
        <Overlay fullScreen inline isOpen={isOverlayOpen} closeCallback={onOverlayToggle}>
          <Slideshow items={imagesSlider} />
        </Overlay>
      </article>
    );
  }
}

Exhibition.propTypes = {
  content: PropTypes.shape({
    copy: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    imagesSlider: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      ...imagePropTypes,
    })).isRequired,
    lead: PropTypes.string,
    tiles: PropTypes.arrayOf(PropTypes.shape(tilePropTypes)),
    place: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};

Exhibition.defaultProps = {
  content: PropTypes.shape({
    lead: '',
    place: '',
    tiles: [],
  }),
};

export default Exhibition;
