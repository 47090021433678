import get from 'lodash.get';

export default function getDataHero(data = {}) {
  const {
    displayInline,
    displayOnce,
    id,
    image,
  } = data;

  const height = get(image, 'image.details.image.height', 1);
  const width = get(image, 'image.details.image.width', 1);

  return {
    displayInline,
    displayOnce,
    id,
    image: {
      alt: get(image, 'description', ''),
      height,
      src: get(image, 'file.url', ''),
      width,
      aspectRatio: width / height,
    },
    title: get(data, 'heading.heading', ''),
  };
}
