import PropTypes from 'prop-types';
import React from 'react';
import dateFormat from 'dateformat';
import { Link } from 'gatsby';

import './exhibition-link.scss';

class ExhibitionLink extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);

    this.state = {
      open: false,
    };
  }

  onClick(ev) {
    const {
      open,
    } = this.state;
    ev.preventDefault();
    this.setState({
      open: !open,
    });
  }

  render() {
    const {
      dateEnd,
      dateStart,
      place,
      url,
    } = this.props;

    const {
      open,
    } = this.state;

    return (
      <li>
        <Link to={url} className="exhibition">
          <time dateTime={dateFormat(dateStart, 'yyyy-mm-dd')} className="exhibition__date--start">
            <span className="exhibition__date-start__part">{dateFormat(dateStart, 'yyyy')}</span>
            <span className="exhibition__date-start__part">{dateFormat(dateStart, 'd mmm')}</span>
          </time>
          <time dateTime={dateFormat(dateEnd, 'yyyy-mm-dd')} className="exhibition__date--end">
            {dateFormat(dateEnd, 'd mmm')}
          </time>
          <span className={`exhibition__place ${open ? ' exhibition__place--open' : ''}`}>
            <span className="exhibition__place__content">
              {place}
            </span>
            <span className="exhibition__place__content">
              {place}
            </span>
            <button onClick={this.onClick} className="exhibition__place__toggle" type="button">
              Toggle
            </button>
          </span>
        </Link>
      </li>
    );
  }
}

const propTypes = {
  dateEnd: PropTypes.string.isRequired,
  dateStart: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

ExhibitionLink.propTypes = propTypes;

export default ExhibitionLink;
export { propTypes };
