import React from 'react';
import PropTypes from 'prop-types';
import dateformat from 'dateformat';
import get from 'lodash.get';
import Images from '../image/images';
import LayoutContextConsumer from '../layout/layout-context-consumer';
import Overlay from '../overlay/overlay';
import Paragraph from '../paragraph/paragraph';
import { getDataImages } from '../../proxies';

import './news.scss';

const News = (props = {}) => {
  const {
    copy,
    date,
    image,
    mapLink,
    moreInformationLink,
    slug,
    title,
  } = props;

  const {
    GATSBY_BASE_URL,
  } = process.env;
  const url = `${GATSBY_BASE_URL}news/${slug}`;

  let headTitle = `## ${title}`;
  if (date) {
    const d = new Date(date);
    headTitle = `## ${dateformat(d, 'yyyy d mmm')}\n${headTitle}`;
  }

  return (
    <LayoutContextConsumer>
      <Overlay>
        <div className="news__head">
          <Images {...getDataImages({ images: [image] })} />
          <Paragraph content={headTitle} />
        </div>
        <div className="news__copy">
          <Paragraph content={get(copy, 'copy', '')} />

          <ul className="news__actions">
            {mapLink ? (
              <li className="news__actions__link">
                <a href={mapLink} target="_blank" rel="noopener noreferrer">View map</a>
              </li>
            ) : '' }
            {moreInformationLink ? (
              <li className="news__actions__link">
                <a href={moreInformationLink}>View more information</a>
              </li>
            ) : '' }
            <li className="news__actions__link desktop-visible">
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer">Share: Facebook</a>
            </li>
            <li className="news__actions__link desktop-visible">
              <a href={`https://twitter.com/home?status=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer">Share: Twitter</a>
            </li>
            <li className="news__actions__link desktop-visible">
              <a href={`mailto:?body=${encodeURIComponent(`${title}\n${url}`)}&subject=DZEK: ${encodeURIComponent(title)}`} target="_blank" rel="noopener noreferrer">Share: Mail</a>
            </li>
            <li className="news__actions__link mobile-visible">
              <span>Share: </span>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer">Facebook</a>
              <span> / </span>
              <a href={`https://twitter.com/home?status=${encodeURIComponent(url)}`} target="_blank" rel="noopener noreferrer">Twitter</a>
              <span> / </span>
              <a href={`mailto:?body=${encodeURIComponent(`${title}\n${url}`)}&subject=DZEK: ${encodeURIComponent(title)}`} target="_blank" rel="noopener noreferrer">Email</a>
            </li>
          </ul>

        </div>
      </Overlay>
    </LayoutContextConsumer>
  );
};

News.propTypes = {
  copy: PropTypes.shape({
    copy: PropTypes.string,
  }),
  date: PropTypes.string,
  image: PropTypes.shape({}),
  mapLink: PropTypes.string,
  moreInformationLink: PropTypes.string,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

News.defaultProps = {
  copy: {
    copy: '',
  },
  date: '',
  image: {},
  mapLink: '',
  moreInformationLink: '',
};

export default News;
