import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';

import './paragraph.scss';

const Paragraph = ({
  content,
  indent,
  typeClass,
}) => (
  <div className={`paragraph${typeClass ? ` paragraph--${typeClass}` : ''}${indent ? ' paragraph--indented' : ''}`}>
    <ReactMarkdown
      escapeHtml={false}
      source={content}
    />
  </div>
);

Paragraph.propTypes = {
  content: PropTypes.string.isRequired,
  indent: PropTypes.bool,
  typeClass: PropTypes.string,
};

Paragraph.defaultProps = {
  indent: false,
  typeClass: '',
};

export default Paragraph;
