import PropTypes from 'prop-types';
import React from 'react';
import Product, { propTypes as productPropTypes } from '../product/product';

import './category.scss';

const Category = ({
  items,
  name,
}) => (
  <div className="category">
    <h2 className="category__heading heading--1">{name}</h2>
    {items.map((item) => {
      const key = item.contentfulId;
      return (
        <Product key={key} {...item} />
      );
    })}
  </div>
);

Category.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(productPropTypes)),
  name: PropTypes.string,
};

Category.defaultProps = {
  items: [],
  name: '',
};

export default Category;
