import React from 'react';
import PropTypes from 'prop-types';
import LayoutContext from './layout-context';

const LayoutContextConsumer = ({ children }) => (
  <LayoutContext.Consumer>
    {context => React.Children.map(children, child => React.cloneElement(child, context))}
  </LayoutContext.Consumer>
);

LayoutContextConsumer.propTypes = {
  children: PropTypes.node.isRequired,
};


export default LayoutContextConsumer;
