export default function getDataDownloads(data = {}) {
  const {
    id,
    content = {
      content: '',
    },
  } = data;

  const downloads = content.content.split(/\n/g)
    .filter(line => line.trim().length)
    .map((line, index) => {
      const format = (line.match(/\[[^\]]+\]/g) || []).shift();
      const link = (line.match(/\([^)]+\)/g) || []).shift();

      return {
        id: id + index,
        format: (format || '').replace(/(\[|\])/g, ''),
        link: (link || '').replace(/(\(|\))/g, '').split(' ').shift() || '',
        text: (line.split('[').shift() || '').replace(/:? *$/g, ''),
      };
    });

  const matches = (downloads.pop() || { text: '' }).text
    .match(/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g);
  const contact = (matches || []).shift() || '';

  return {
    downloads,
    contact,
  };
}
