import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Award from './award';

import LoadMore from '../load-more/load-more';
import { contentful } from '../../utilities';

import './awards.scss';

const formatNewItems = function formatNewNewsItemsFromContentful(items) {
  return items.map(item => ({
    contentful_id: item.sys.id,
    year: parseInt(item.fields.date.substr(0, 4), 10),
    id: item.sys.id,
    description: item.fields.title,
  }));
};

class Awards extends Component {
  constructor(props) {
    super(props);
    const {
      awards = [],
      total,
    } = this.props;

    this.limit = awards.length;
    this.total = total;
    this.state = {
      awards,
      canLoadMore: total > awards.length,
    };

    this.loadMore = this.loadMore.bind(this);
    this.processNewItems = this.processNewItems.bind(this);
  }

  loadMore(ev) {
    ev.preventDefault();
    const { awards = [] } = this.state;
    contentful.loadAwards(awards.length, this.limit)
      .then(this.processNewItems);
  }

  processNewItems(newItems) {
    const { total } = this;
    this.setState((state) => {
      const newAwards = [
        ...state.awards,
        ...formatNewItems(newItems),
      ];
      return {
        awards: newAwards,
        canLoadMore: total > newAwards.length,
      };
    });
  }

  render() {
    const {
      awards,
      canLoadMore,
    } = this.state;
    return (
      <Fragment>
        <div className="awards__title">Awards</div>
        <div className="awards">
          <ul className="awards__list">
            {awards.map(award => (
              <Award {...award} key={award.id} />
            ))}
          </ul>
          {canLoadMore && (<LoadMore text="Load More Awards" loadMore={this.loadMore} />)}
        </div>
      </Fragment>
    );
  }
}

Awards.propTypes = {
  awards: PropTypes.arrayOf(PropTypes.shape).isRequired,
  total: PropTypes.number,
};

Awards.defaultProps = {
  total: 0,
};

export default Awards;
