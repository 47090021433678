import React from 'react';
import PropTypes from 'prop-types';

import './breadcrumbs.scss';

const Breadcrumbs = ({ breadcrumbs }) => (
  <ol className="breadcrumb">
    {breadcrumbs.map(item => (
      <li className="breadcrumb" key={item}>{item}</li>
    ))}
  </ol>
);

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.string),
};

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
};

export default Breadcrumbs;
