import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { isMobile } from '../../helpers';
import Picture from '../picture/picture';
import { imagePropTypes } from '../image/image';

import './slideshow.scss';

const CLASS_CURRENT = 'is-current';
const SCROLL_SENSITIVITY = 10;
const SCROLL_THRESHOLD = 600;

class Slideshow extends React.Component {
  constructor(props) {
    super(props);
    const {
      items,
    } = this.props;

    this.clearScrollTreshold = this.clearScrollTreshold.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.showNext = this.showNext.bind(this);
    this.showPrev = this.showPrev.bind(this);

    this.count = items.length;
    this.scrollTreshold = null;
    this.state = {
      current: items.length - 1,
    };
  }

  onNextClick(ev) {
    ev.preventDefault();
    this.showNext();
  }

  onPrevClick(ev) {
    ev.preventDefault();
    this.showPrev();
  }

  onWheel(ev) {
    if (isMobile()) {
      return;
    }
    ev.preventDefault();

    const {
      deltaY,
    } = ev;
    const go = deltaY > 0 ? this.showNext : this.showPrev;

    if (Math.abs(deltaY) > SCROLL_SENSITIVITY && !this.scrollTreshold) {
      go();
      this.scrollTreshold = setTimeout(this.clearScrollTreshold, SCROLL_THRESHOLD);
    }
  }

  clearScrollTreshold() {
    clearTimeout(this.scrollTreshold);
    this.scrollTreshold = null;
  }

  showNext() {
    const {
      current,
    } = this.state;

    if (current > 0) {
      this.setState({
        current: current - 1,
      });
    }
  }

  showPrev() {
    const {
      current,
    } = this.state;

    if (current < this.count - 1) {
      this.setState({
        current: current + 1,
      });
    }
  }

  renderItem(image, index) {
    const {
      current,
    } = this.state;

    return (
      <figure key={`slide-${index}-${image.id}`} className={`slideshow__item ${index === current ? CLASS_CURRENT : ''}`}>
        <Picture image={image} />
        <div className="slideshow__counter">{`${this.count - index}/${this.count}`}</div>
      </figure>
    );
  }

  render() {
    const {
      items = [],
      slideshowCaption,
      type,
    } = this.props;

    return (
      <div className={`slideshow ${type}`} onWheel={this.onWheel}>
        {[...items]
          .reverse()
          .map(this.renderItem)}
        <ReactMarkdown
          allowedTypes={['break', 'paragraph', 'text']}
          className="slideshow__description__text"
          source={slideshowCaption}
          unwrapDisallowed
        />
        <button className="slideshow__nav" onClick={this.onNextClick} type="button" />
      </div>
    );
  }
}

Slideshow.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(imagePropTypes)).isRequired,
  type: PropTypes.string,
  slideshowCaption: PropTypes.string,
};

Slideshow.defaultProps = {
  type: 'slideshow--gallery',
  slideshowCaption: '',
};

export default Slideshow;
