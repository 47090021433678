import get from 'lodash.get';

const mapImage = function mapImageData(item) {
  const {
    id,
    description = '',
    title = '',
    file = {},
  } = item;

  const height = get(file, 'details.image.height', 1);
  const width = get(file, 'details.image.width', 1);

  return {
    key: id,
    caption: description,
    image: {
      alt: description || title || '',
      height,
      src: get(file, 'url', ''),
      width,
      aspectRatio: width / height,
    },
  };
};

export default function getDataImages(data = {}) {
  const {
    images = [],
    layout = '8 cols',
    verticalAlign = 'top',
  } = data;

  return {
    layout: layout.replace(/[^A-z0-9]+/g, '-'),
    images: images.filter(item => !!item).map(mapImage),
    verticalAlign,
  };
}
