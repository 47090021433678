import PropTypes from 'prop-types';
import React from 'react';

import './material-specs.scss';

const MaterialSpecs = ({ specs }) => (
  <table className="materialSpecs">
    <thead>
      <tr className="materialSpecs__title">
        <th className="materialSpecs__type">
          type
        </th>
        <th className="materialSpecs__size">
          size
        </th>
        <th className="materialSpecs__thickness">
          thickness
        </th>
        <th className="materialSpecs__finishes">
          finish
        </th>
      </tr>
    </thead>
    <tbody>
      {specs.map(item => (
        <tr className="materialSpecs__item" key={item.id}>
          <td className="materialSpecs__type">
            {item.type}
          </td>
          <td className="materialSpecs__size">
            {item.size.vertical}
            &nbsp;✕&nbsp;
            {item.size.horizontal}
            cm
          </td>
          <td className="materialSpecs__thickness">
            {item.thickness.map(thicknes => (
              <span key={thicknes} className="materialSpecs__thicknes">
                {thicknes}
                &nbsp;cm
              </span>
            ))}
          </td>
          <td className="materialSpecs__finishes">
            {item.finishes.map(finish => (
              <span key={`${item.type}-${finish}`} className="materialSpecs__finish">
                {finish}
              </span>
            ))}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

MaterialSpecs.propTypes = {
  specs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      size: PropTypes.shape({
        vertical: PropTypes.number.isRequired,
        horizontal: PropTypes.number.isRequired,
      }).isRequired,
      thickness: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.array,
      ]).isRequired,
      finishes: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
      ]).isRequired,
    }),
  ),
};

MaterialSpecs.defaultProps = {
  specs: [],
};

export default MaterialSpecs;
