import PropTypes from 'prop-types';
import React from 'react';

import './downloads-contact.scss';

const DownloadsAndContact = ({ downloads, contact }) => (
  <div className="downloadsContact">
    <ul className="downloadsContact__items">
      {downloads.map(({
        format,
        id,
        link,
        text,
      }) => (
        <li key={id} className="downloadsContact__item">
          <a href={link} target="_blank" rel="noopener noreferrer">
            <span className="downloadsContact__item__label">{text}</span>
            <span className="downloadsContact__item__format">{format}</span>
          </a>
        </li>
      ))}
      <li key="contact" className="downloadsContact__item downloadsContact__item--contact">
        <a href={`mailto:${contact}`}>
          <span className="downloadsContact__item__label">Enquire</span>
          <span className="downloadsContact__item__format">{contact}</span>
        </a>
      </li>
    </ul>
  </div>
);

DownloadsAndContact.propTypes = {
  downloads: PropTypes.arrayOf(PropTypes.object).isRequired,
  contact: PropTypes.string.isRequired,
};

export default DownloadsAndContact;
