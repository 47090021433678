export default function getDataProductSpecs(data = '') {
  const contact = {
    email: '',
    file: '',
    format: '',
  };
  let text = data;

  const matchEmail = text.match(/Enquire: \[EMAIL\]\([^)]+\)/g);
  if (matchEmail) {
    const phrase = matchEmail.shift();
    contact.email = phrase.split(':').pop().replace(')', '');
    text = text.replace(phrase, '');
  }

  const fileEmail = text.match(/Technical page: \[[^\]]+\]\([^)]+\)/g);
  if (fileEmail) {
    const phrase = fileEmail.shift();
    contact.format = phrase.split('[')[1].split(']').shift();
    contact.file = phrase.split('(').pop().replace(')', '');
    text = text.replace(phrase, '');
  }

  const specification = text.split('\n')
    .filter(line => line.trim().length)
    .map(line => ({
      title: line.split(':').shift().trim(),
      description: line.substr(line.indexOf(':') + 1).trim(),
    }));

  return {
    specification,
    contact,
  };
}
