import PropTypes from 'prop-types';
import React, {
  Component,
  Fragment,
} from 'react';
import { propTypes } from './nav-target';
import {
  getSiblingMargin,
  isClient,
  offset,
} from '../../helpers';
import {
  niceScrollTo,
  scrolling,
} from '../../utilities';
import {
  HEADER_HEIGHT,
  SIDE_NAV_OFFSET,
} from '../../config';

import './side-nav.scss';

class SideNav extends Component {
  constructor(props) {
    super(props);

    this.enableScrolling = this.enableScrolling.bind(this);
    this.getNavTree = this.getNavTree.bind(this);
    this.navTargetInView = this.navTargetInView.bind(this);
    this.onNavLinkClick = this.onNavLinkClick.bind(this);
    this.renderLink = this.renderLink.bind(this);
    this.stickySideNav = this.stickySideNav.bind(this);

    this.activeTimeout = null;
    this.mountPoint = '';
    this.updateTimeout = null;

    this.state = {
      active: [''],
      navFixed: false,
      scrollingEnabled: true,
    };
  }

  componentDidMount() {
    if (isClient()) {
      scrolling.addCallback('navTarget', this.navTargetInView);
      scrolling.addCallback('sideNav', this.stickySideNav);
      this.mountPoint = window.location.pathname;
    }
  }

  componentDidUpdate() {
    if (isClient()) {
      this.updateClient();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.activeTimeout);
    clearTimeout(this.updateTimeout);
  }

  onNavLinkClick(ev) {
    ev.preventDefault();
    const { pathname } = window.location;
    const { links } = this.props;
    const el = ev.currentTarget;
    const name = el.getAttribute('href').replace('#', '');
    const target = document.querySelector(`[name="${name}"]`);
    const targetIndex = links.findIndex(link => link.slug === name);

    this.setState({
      active: [...links.filter((link, index) => index <= targetIndex).map(link => link.slug)],
      scrollingEnabled: false,
    });
    const state = `${pathname}#${name}`;
    window.history.replaceState(null, '', state);
    niceScrollTo({
      callback: this.enableScrolling,
      speed: 1,
      y: offset(target).top - HEADER_HEIGHT + getSiblingMargin(target) + 1,
    });
  }

  // NOTE this method can produce infinite loop and crash the browser. Handle with care.
  getNavTree(links, root) {
    const { getNavTree } = this;

    return links.filter((link) => {
      if (root === '') {
        return link.parent === null || link.parent === '';
      }
      if (link.parent) {
        return link.parent.indexOf(root) > -1;
      }
      return false;
    }).map(link => ({
      ...link,
      children: getNavTree(links, link.title),
    }));
  }

  setActive(newActive = []) {
    const { active } = this.state;

    let matches = true;
    newActive.forEach((item, key) => {
      if (active[key] !== item) {
        matches = false;
      }
    });

    if (matches) {
      return;
    }

    this.setState({
      active: newActive,
    });
  }

  updateClient() {
    const {
      hash,
      pathname,
    } = window.location;
    const { active } = this.state;
    const last = [...active].pop() || '';

    scrolling.rebuildCache();

    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
      if (hash.substr(1) !== last && this.mountPoint === pathname) {
        const state = `${pathname}${last ? `#${last}` : ''}`;
        window.history.replaceState(null, '', state);
      }
    }, 250);
  }

  enableScrolling() {
    this.setState({
      scrollingEnabled: true,
    });
  }

  navTargetInView(target) {
    const {
      scrollingEnabled,
    } = this.state;

    if (!scrollingEnabled) {
      return target;
    }

    const setActiveTimeout = 100;

    // Get visible els
    const newActive = scrolling.getCache().filter(item => (
      item.callback === 'navTarget'
      && item.states
      && item.states.isPastHalf
      && item.states.isInView
    )).map((item) => {
      if (item.slug || item.slug === '') {
        return item.slug;
      }

      item.slug = item.el.getAttribute('name'); // eslint-disable-line no-param-reassign
      return item.slug;
    });

    clearTimeout(this.activeTimeout);
    this.activeTimeout = setTimeout(
      this.setActive(newActive),
      setActiveTimeout,
    );

    return target;
  }

  stickySideNav(target, scrollTop) {
    const { withInlineHero } = this.props;
    const { navFixed } = this.state;
    const offsetTop = withInlineHero
      ? (window.innerWidth * 56.25 / 100) + SIDE_NAV_OFFSET : SIDE_NAV_OFFSET;

    if (navFixed) {
      if (scrollTop + HEADER_HEIGHT <= offsetTop) {
        this.setState({
          navFixed: false,
        });
      }
    } else if (scrollTop + HEADER_HEIGHT > offsetTop) {
      this.setState({
        navFixed: true,
      });
    }

    return target;
  }

  renderLink(link, level = 0) {
    const {
      onNavLinkClick,
      renderLink,
      state,
    } = this;
    const {
      children = [],
      id,
      slug,
      title,
    } = link;

    const { active } = state;
    const isActive = active.length && active.indexOf(slug) === active.length - 1;

    return (
      <Fragment key={`nav-${id}`}>
        <a
          className={`level--${level}${isActive ? ' is-active' : ''}`}
          href={`#${slug}`}
          onClick={onNavLinkClick}
        >
          {title}
        </a>
        {children.map(child => renderLink(child, level + 1))}
      </Fragment>
    );
  }

  render() {
    const {
      links,
      withInlineHero,
    } = this.props;
    const {
      navFixed,
    } = this.state;
    const { renderLink } = this;

    const navTree = this.getNavTree(links, '');

    if (!links.length) {
      return null;
    }

    return (
      <div
        className={`side-nav js-scroll${withInlineHero ? ' side-nav--lower' : ''}${navFixed ? ' side-nav--fixed' : ''}`}
        data-callback="sideNav"
      >
        {navTree.map(link => renderLink(link))}
      </div>
    );
  }
}

SideNav.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape(propTypes)),
  withInlineHero: PropTypes.bool,
};
SideNav.defaultProps = {
  links: [],
  withInlineHero: false,
};

export default SideNav;
