import PropTypes from 'prop-types';
import React from 'react';

import './load-more.scss';

const LoadMore = ({ loadMore, text }) => (
  <button className="loadMore" onClick={loadMore} type="button">
    {text}
  </button>
);

LoadMore.propTypes = {
  loadMore: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default LoadMore;
