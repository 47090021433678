import PropTypes from 'prop-types';
import React from 'react';

const NavTarget = ({ title, id, slug }) => (
  <span
    className="nav-target is-hidden js-scroll"
    data-callback="navTarget"
    key={id}
    name={slug || title.replace(/[^A-z0-9]+/g, '-').toLowerCase()}
  />
);

const propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
};

NavTarget.propTypes = propTypes;

NavTarget.defaultProps = {
  slug: '',
};

export default NavTarget;

export {
  propTypes,
};
