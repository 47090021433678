import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import Breadcrumbs from './breadcrumbs';
import Picture from '../picture/picture';
import { imagePropTypes } from '../image/image';
import './tile.scss';

const Tile = (props) => {
  const {
    breadcrumbs,
    description,
    image,
    link,
    marginBottom,
    size,
    title,
  } = props;

  const isPortrait = image.height > image.width;

  const TILE_SIZE = {
    medium: 4,
    large: 6,
    extraLarge: 8,
  };

  const TileContent = (
    <React.Fragment>
      <div className={`tile__media${isPortrait ? ' tile__media--portrait' : ''}`}>
        <div className="tile__media__img" style={{ paddingTop: `${(image.height / image.width) * 100}%` }}>
          <Picture image={image} cols={TILE_SIZE[size] || 8} />
          {!title || (
            <p className="tile__imgText">{title}</p>
          )}
        </div>
        {!breadcrumbs.length || (
          <ol className="tile__breadcrumbs">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </ol>
        )}
      </div>
      <p className="tile__paragraph">{description}</p>
    </React.Fragment>
  );

  if (link) {
    return (
      <Link to={link} className={`tile tile--${size}`}>
        {TileContent}
      </Link>
    );
  }
  return (
    <div className={`tile tile--${size} ${marginBottom ? `tile__margin--${marginBottom}` : ''}`}>
      {TileContent}
    </div>
  );
};

const propTypes = {
  breadcrumbs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  description: PropTypes.string,
  image: PropTypes.shape(imagePropTypes).isRequired,
  link: PropTypes.string,
  marginBottom: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string.isRequired,
};

Tile.propTypes = propTypes;

Tile.defaultProps = {
  breadcrumbs: [],
  description: '',
  link: '',
  marginBottom: '',
  title: '',
};

export default Tile;

export {
  propTypes,
};
