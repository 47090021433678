import PropTypes from 'prop-types';
import React from 'react';
import Picture from '../picture/picture';
import { propTypes, imagePropTypes } from '../../prop-types/image';

import './image.scss';

const Image = ({
  image,
  caption,
  layoutCols,
  verticalAlign,
}) => (
  <div className={`imageBox imageBox--${layoutCols} imageBox--${verticalAlign}`}>
    <div className="imageBox__wrap" style={{ paddingTop: `${(1 / image.aspectRatio) * 100}%` }}>
      <Picture className="imageBox__img" image={image} cols={parseInt(layoutCols.replace('cols', ''), 10)} />
    </div>
    {!caption || (
      <p className="imageBox__caption">
        {caption}
      </p>
    )}
  </div>
);

Image.propTypes = propTypes;

Image.defaultProps = {
  caption: '',
  image: PropTypes.shape({
    alt: '',
  }),
  layoutCols: 'cols8',
  verticalAlign: 'top',
};

export default Image;

export {
  imagePropTypes,
  propTypes,
};
