import get from 'lodash.get';
import getDataProductSpecs from './get-data-product-specs';

export default function getDataProduct(data = {}) {
  const {
    contentful_id, // eslint-disable-line camelcase
    id,
    images = [],
    name,
    slug,
  } = data;

  return {
    ...getDataProductSpecs(get(data, 'details.details', '')),
    contentfulId: contentful_id,
    description: get(data, 'description.description', ''),
    id,
    images: images.map((item) => {
      const height = get(item, 'file.details.image.height', 1);
      const width = get(item, 'file.details.image.width', 1);
      return {
        alt: item.description || item.title || '',
        height,
        id: item.contentful_id,
        src: get(item, 'file.url', ''),
        text: item.description,
        width,
        aspectRatio: width / height,
      };
    }),
    name,
    slug,
  };
}
