import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import Tile, { propTypes as tilePropTypes } from './tile';
import './tiles.scss';

const Tiles = ({
  contentfulId,
  items,
}) => (
  <div className="tiles">
    {items.map((item, index) => {
      const key = md5(contentfulId + item.contentfulId + index);
      return (
        <Tile
          {...item}
          key={key}
        />
      );
    })}
  </div>
);

Tiles.propTypes = {
  contentfulId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(tilePropTypes)).isRequired,
};

export default Tiles;
