export default function getDataNavTarget(data = {}) {
  const {
    contentfulparent,
    id,
    slug,
    title,
  } = data;

  return {
    id,
    parent: contentfulparent,
    slug: slug || title.replace(/[^A-z0-9]+/g, '-').toLowerCase(),
    title,
  };
}
