import PropTypes from 'prop-types';
import React from 'react';

import './award.scss';

class Award extends React.Component {
  constructor(props) {
    super(props);

    this.timeout = null;
    this.onClick = this.onClick.bind(this);

    this.state = {
      isVisible: false,
      open: false,
    };

    this.makeVisible = this.makeVisible.bind(this);
  }

  componentDidMount() {
    this.timeout = setTimeout(this.makeVisible, 100);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onClick(ev) {
    const {
      open,
    } = this.state;
    ev.preventDefault();
    this.setState({
      open: !open,
    });
  }

  makeVisible() {
    this.setState({
      isVisible: true,
    });
  }

  render() {
    const {
      description,
      year,
    } = this.props;

    const {
      isVisible,
      open,
    } = this.state;

    const classes = [
      'award',
      isVisible ? 'is-visible' : null,
    ];

    return (
      <li className={classes.join(' ')}>
        <time dateTime={year} className="award__year">
          {year}
        </time>
        <span className={`award__description ${open ? ' award__description--open' : ''}`}>
          <span className="award__description__content">
            {description}
          </span>
          <span className="award__description__content">
            {description}
          </span>
          <button onClick={this.onClick} className="award__description__toggle" type="button">
            Toggle
          </button>
        </span>
      </li>
    );
  }
}

Award.propTypes = {
  description: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
};

export default Award;
