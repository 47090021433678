import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import dateFormat from 'dateformat';
import { Link } from 'gatsby';
import LinkList from '../link-list/link-list';
import Picture from '../picture/picture';
import { imagePropTypes } from '../image/image';

import './news-tile.scss';

class NewsTile extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      isVisible: false,
    };
    this.makeVisible = this.makeVisible.bind(this);
  }

  componentDidMount() {
    this.timeout = setTimeout(this.makeVisible, 100);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  makeVisible() {
    this.setState({
      isVisible: true,
    });
  }

  render() {
    const {
      copy,
      date,
      fullscreen,
      id,
      image,
      imageTitle,
      mapLink,
      moreInformationLink,
      place,
      slug = '',
      title,
    } = this.props;

    const { isVisible } = this.state;

    const url = `${process.env.GATSBY_BASE_URL}news/${slug}`;
    const day = dateFormat(date, 'd');
    const month = dateFormat(date, 'mmmm');
    const year = dateFormat(date, 'yyyy');

    const NewsTitleImage = !image.src || (
      <div className="newsTile__image__container">
        <div
          className="newsTile__image__wrap"
          style={{ paddingTop: `${(image.height / image.width) * 100}%` }}
        >
          <div className="newsTile__image__wrap__title">{imageTitle}</div>
          <Picture image={image} cols={4} />
        </div>
      </div>
    );

    const links = [
      {
        id: `${id}_mail`,
        label: 'Share: Mail',
        url: `mailto:?subject=${encodeURIComponent(`Dzek - ${title}`)}&body=${encodeURIComponent(url)}`,
      },
      {
        id: `${id}_twitter`,
        label: 'Share: Twitter',
        url: `http://www.twitter.com/share?text=${title};url=${url}`,
      },
      {
        id: `${id}_fb`,
        label: 'Share: Facebook',
        url: `http://www.facebook.com/sharer/sharer.php?u=${url}`,
      },
    ];

    if (moreInformationLink) {
      links.unshift({
        id: `${id}_more`,
        label: 'View more information',
        url: moreInformationLink,
      });
    }

    if (place) {
      links.unshift({
        id: `${id}_map`,
        label: 'View Map',
        url: `https://maps.google.com?q=${encodeURIComponent(place)}`,
      });
    } else if (mapLink) {
      links.unshift({
        id: `${id}_map`,
        label: 'View Map',
        url: mapLink,
      });
    }

    const classes = [
      'newsTile',
      isVisible ? 'is-visible' : null,
    ];

    return (
      <article className={classes.join(' ')}>
        <div className="newsTile__heading">
          <time dateTime={date} className="newsTile__date">
            <span className="newsTile__date__year">{year}</span>
            <span className="newsTile__date__day">{day}</span>
            {month}
          </time>
          <h3 className="newsTile__title">{title}</h3>
        </div>
        <div className="newsTile__content">
          <div className={`newsTile__image${fullscreen ? ' newsTile__image--fullscreen' : ''}`}>
            <Link to={`/news/${slug}`}>
              {NewsTitleImage}
            </Link>
          </div>
          <div className="newsTile__text">
            <ReactMarkdown
              escapeHtml={false}
              source={copy}
            />
            <LinkList links={links} />
          </div>
        </div>
      </article>
    );
  }
}

NewsTile.propTypes = {
  id: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  fullscreen: PropTypes.bool,
  image: PropTypes.shape(imagePropTypes),
  imageTitle: PropTypes.string,
  place: PropTypes.string,
  mapLink: PropTypes.string,
  moreInformationLink: PropTypes.string,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

NewsTile.defaultProps = {
  fullscreen: false,
  imageTitle: '',
  image: {},
  place: '',
  mapLink: '',
  moreInformationLink: '',
};

export default NewsTile;
