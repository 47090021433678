import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import ExpandingImages from '../expanding-images/expanding-images';
import ProductSpecs from '../product-specs/product-specs';
import { imagePropTypes } from '../image/image';

import './product.scss';

const Product = ({
  contact,
  specification,
  description,
  images,
  name,
}) => (
  <div className="product">
    <h3 className="product__heading heading--2">{name}</h3>
    <ReactMarkdown
      className="product__description"
      escapeHtml={false}
      source={description}
    />
    <ExpandingImages images={images} />
    <ProductSpecs contact={contact} specification={specification} />
  </div>
);

const propTypes = {
  contact: PropTypes.shape({
    email: PropTypes.string,
    file: PropTypes.string,
    format: PropTypes.string,
  }),
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
    ...imagePropTypes,
  })),
  name: PropTypes.string,
  specification: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
  })),
};

Product.propTypes = propTypes;

Product.defaultProps = {
  contact: {},
  description: '',
  images: [],
  name: '',
  specification: [],
};

export default Product;

export {
  propTypes,
};
