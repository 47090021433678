import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { imagePropTypes } from '../../prop-types/image';

import './picture.scss';

import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_MAX,
  // BREAKPOINT_MIN,
  BREAKPOINT_MOBILE,
  BREAKPOINT_PHONE,
  BREAKPOINT_RETINA,
} from '../../config';

const getSrcSet = function getImageSrcSet({
  height = false,
  src,
  width,
}) {
  const params = '&fit=fill&q=70';
  const path = [`${src}?w=${width}${params}`];
  if (height) {
    path.push(`&h=${height}`);
  }
  if (width < BREAKPOINT_RETINA) {
    const x2width = Math.min(width * 2, BREAKPOINT_RETINA);
    path.push(` 1x,
      ${src}?w=${x2width}${params}`);
    if (height) {
      path.push(`&h=${Math.round(x2width / width * height)}`);
    }
    path.push(' 2x');
  }
  return path.join('');
};

class Picture extends Component {
  constructor(props) {
    super(props);

    this.getDefaultSource = this.getDefaultSource.bind(this);
    this.getSource = this.getSource.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);

    this.imageRef = React.createRef();
    this.timeout = null;
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const img = this.imageRef.current;
    if (img && img.complete) {
      this.handleImageLoaded();
    }

    // Show the image anyway after 2s
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.handleImageLoaded();
    }, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  getDefaultSource() {
    const {
      cols,
      image,
      imageMobile,
    } = this.props;
    const imgMobile = { ...(imageMobile.src ? imageMobile : image) };

    return [
      {
        media: `(min-width: ${BREAKPOINT_MAX}px)`,
        srcSet: getSrcSet({ src: image.src, width: Math.round(BREAKPOINT_RETINA * cols / 12) }),
      },
      {
        media: `(min-width: ${BREAKPOINT_DESKTOP}px) and (max-width: ${BREAKPOINT_MAX - 1}px)`,
        srcSet: getSrcSet({ src: image.src, width: Math.round(BREAKPOINT_MAX * cols / 12) }),
      },
      {
        media: `(min-width: ${BREAKPOINT_MOBILE}px) and (max-width: ${BREAKPOINT_DESKTOP - 1}px)`,
        srcSet: getSrcSet({ src: image.src, width: Math.round(BREAKPOINT_DESKTOP * cols / 12) }),
      },
      {
        media: `(min-width: ${BREAKPOINT_PHONE}px) and (max-width: ${BREAKPOINT_MOBILE - 1}px)`,
        srcSet: getSrcSet({ src: imgMobile.src, width: BREAKPOINT_MOBILE }),
      },
      {
        media: `(max-width: ${BREAKPOINT_PHONE - 1}px)`,
        srcSet: getSrcSet({ src: imgMobile.src, width: BREAKPOINT_PHONE }),
      },
    ];
  }

  getSource() {
    const {
      customSource,
      imageSize,
    } = this.props;

    if (customSource.length) {
      return customSource;
    }

    if (imageSize.length) {
      return imageSize
        .sort((a, b) => {
          if (a.minWidth < b.minWidth) return -1;
          if (a.minWidth > b.minWidth) return 1;
          return 0;
        })
        .map((current, index, arr) => {
          const {
            minWidth,
            img,
          } = current;

          const media = [`(min-width: ${minWidth}px)`];
          if (index - 1 > -1) {
            media.push(` and (max-width: ${arr[index - 1].minWidth - 1}px)`);
          }

          return {
            media: media.join(''),
            srcSet: getSrcSet(img),
          };
        });
    }

    return this.getDefaultSource();
  }

  handleImageLoaded() {
    const {
      fadeIn,
      onLoadCallback,
    } = this.props;
    const { loaded } = this.state;

    if (fadeIn && !loaded) {
      this.setState({ loaded: true });
      onLoadCallback();
    }
  }

  render() {
    const {
      className,
      image,
      style,
    } = this.props;
    const { loaded } = this.state;

    return (
      <picture>
        {this.getSource().map(soureProps => (
          <source key={soureProps.srcSet} {...soureProps} />
        ))}
        <img
          alt={image.alt}
          className={className}
          onLoad={this.handleImageLoaded}
          ref={this.imageRef}
          src={`${image.src}?q=70`}
          style={loaded ? { ...style } : { opacity: 0, ...style }}
        />
      </picture>
    );
  }
}

/* eslint-disable react/no-unused-prop-types */
Picture.propTypes = {
  className: PropTypes.string,
  cols: PropTypes.number,
  customSource: PropTypes.arrayOf(PropTypes.shape({})),
  fadeIn: PropTypes.bool,
  image: PropTypes.shape(imagePropTypes),
  imageMobile: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string,
    width: PropTypes.number,
    aspectRatio: PropTypes.number,
  }),
  imageSize: PropTypes.arrayOf(PropTypes.shape({})),
  onLoadCallback: PropTypes.func,
  style: PropTypes.shape({}),
};

Picture.defaultProps = {
  className: '',
  cols: 12,
  customSource: [],
  fadeIn: true,
  image: {},
  imageMobile: {},
  imageSize: [],
  onLoadCallback: () => {},
  style: {},
};

export default Picture;
