import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import get from 'lodash.get';
import NewsTile from './news-tile';

import './news-tiles.scss';

import LoadMore from '../load-more/load-more';
import { contentful } from '../../utilities';

const formatNewItems = function formatNewsItemsFromContentfulApi(items) {
  return items.map(item => ({
    contentful_id: item.sys.id,
    copy: get(item, 'fields.copy', ''),
    date: get(item, 'fields.date', ''),
    id: item.sys.id,
    image: {
      contentful_id: get(item, 'fields.image.sys.id', ''),
      description: get(item, 'fields.image.fields.description', ''),
      id: get(item, 'fields.image.sys.id', ''),
      title: get(item, 'fields.image.fields.title', ''),
      aspectRatio: get(item, 'fields.image.fields.file.details.image.height', 1) / get(item, 'fields.image.fields.file.details.image.width', 0),
      height: get(item, 'fields.image.fields.file.details.image.height', 0),
      src: `${get(item, 'fields.image.fields.file.url', '')}`,
      srcSet: '',
      width: get(item, 'fields.image.fields.file.details.image.width', 0),
    },
    mapLink: get(item, 'fields.mapLink', ''),
    moreInformationLink: get(item, 'fields.moreInformationLink', ''),
    slug: get(item, 'fields.slug', ''),
    title: get(item, 'fields.title', ''),
  }));
};

class NewsTiles extends Component {
  constructor(props) {
    super(props);
    const {
      posts = [],
      total,
    } = this.props;

    this.limit = posts.length;
    this.total = total;
    this.state = {
      canLoadMore: total > posts.length,
      posts,
    };

    this.loadMore = this.loadMore.bind(this);
    this.processNewItems = this.processNewItems.bind(this);
  }

  loadMore(ev) {
    ev.preventDefault();
    const { posts = [] } = this.state;
    contentful.loadNews(posts.length, this.limit)
      .then(this.processNewItems);
  }

  processNewItems(newItems) {
    const { total } = this;
    this.setState((state) => {
      const newPosts = [
        ...state.posts,
        ...formatNewItems(newItems),
      ];
      return {
        posts: newPosts,
        canLoadMore: total > newPosts.length,
      };
    });
  }

  render() {
    const {
      canLoadMore,
      posts,
    } = this.state;

    return (
      <Fragment>
        <div className="news-tiles__title">News</div>
        <div className="news-tiles">
          {posts.map(post => (
            <NewsTile
              key={post.id}
              {...post}
            />
          ))}
          {canLoadMore && (<LoadMore text="Load More News" loadMore={this.loadMore} />)}
        </div>
      </Fragment>
    );
  }
}

NewsTiles.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape).isRequired,
  total: PropTypes.number,
};

NewsTiles.defaultProps = {
  total: 0,
};

export default NewsTiles;
