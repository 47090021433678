import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import Picture from '../picture/picture';

import './video.scss';

class Video extends Component {
  constructor(props) {
    super(props);

    this.onPlay = this.onPlay.bind(this);
    this.onStop = this.onStop.bind(this);

    this.videoRef = React.createRef();
    this.buttonRef = React.createRef();
    this.focusOn = null;

    this.state = {
      playing: false,
    };
  }

  componentDidUpdate() {
    if (this.focusOn) {
      this.focusOn.focus(); // eslint-disable-line react/prop-types
      this.focusOn = null;
    }
  }

  onPlay() {
    this.focusOn = this.videoRef.current.wrapper.querySelector('iframe, video');
    this.setState({
      playing: true,
    });
  }

  onStop() {
    this.focusOn = this.buttonRef.current;
    this.setState({
      playing: false,
    });
  }

  render() {
    const {
      caption,
      className,
      image,
      videoUrl,
    } = this.props;
    const { playing } = this.state;

    return (
      <div className={`video${className ? ` ${className}` : ''}`}>
        <div className="video__wrap">
          <ReactPlayer
            aria-hidden={!playing}
            className="video__player"
            controls
            height="100%"
            onEnded={this.onStop}
            playing={playing}
            ref={this.videoRef}
            tabIndex={playing ? 1 : -1}
            url={videoUrl}
            width="100%"
          />
          <aside
            aria-hidden={playing}
            className="video__controls"
          >
            {image && (
              <Picture className="video__controls__poster" image={image} />
            )}
          </aside>
          <button
            aria-hidden={playing}
            className="video__controls__button"
            onClick={this.onPlay}
            ref={this.buttonRef}
            tabIndex={playing ? -1 : 0}
            type="button"
          >
            Play
          </button>
        </div>
        {caption && (
          <p className="video__caption">
            {caption}
          </p>
        )}
      </div>
    );
  }
}

Video.propTypes = {
  caption: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.shape({}),
  videoUrl: PropTypes.string.isRequired,
};

Video.defaultProps = {
  caption: '',
  className: '',
  image: {},
};

export default Video;
