export default function getDataLink(data = {}) {
  const {
    buttons = [],
    type,
  } = data;

  return {
    buttons: buttons.map(buttonLink => ({
      link: buttonLink.link,
      text: buttonLink.title,
    })),
    narrow: type === 'Normal',
  };
}
