import PropTypes from 'prop-types';

const imagePropTypes = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  height: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

const propTypes = {
  caption: PropTypes.string,
  image: PropTypes.shape(imagePropTypes),
  layoutCols: PropTypes.string,
  verticalAlign: PropTypes.string,
};

export {
  imagePropTypes,
  propTypes,
};
