import get from 'lodash.get';

const getImageData = function getImageData(props = {}) {
  const title = get(props, 'title', '');
  const file = get(props, 'file', {});
  const height = get(file, 'details.image.height', 1);
  const width = get(file, 'details.image.width', 1);
  return {
    alt: title,
    height,
    src: get(file, 'url', ''),
    width,
    aspectRatio: width / height,
  };
};

const formatTileData = function formatSingleTileData(data = {}) {
  const {
    caption,
    contentful_id, // eslint-disable-line camelcase
    id,
    image = {},
    link,
    size,
    title,
  } = data;

  return {
    breadcrumbs: [caption],
    contentfulId: contentful_id,
    description: get(data, 'description.description', ''),
    id,
    image: getImageData(image),
    link,
    size,
    title,
  };
};

export default function getDataTiles(data = {}) {
  return {
    contentfulId: data.contentful_id,
    items: data.tiles.map(formatTileData),
  };
}
