import get from 'lodash.get';
import getDataTiles from './get-data-tiles';

const mapImage = function mapImageData(image) {
  const {
    contentful_id, // eslint-disable-line camelcase
    description = '',
    file,
    id,
    title = '',
  } = image;

  const width = get(file, 'details.image.width', 1);
  const height = get(file, 'details.image.height', 1);

  return {
    alt: title,
    contentfulId: contentful_id,
    description,
    height,
    id,
    src: get(file, 'url', ''),
    width,
    aspectRatio: width / height,
  };
};

export default function getDataExhibition(data = {}) {
  const {
    contentful_id, // eslint-disable-line camelcase
    dateFrom,
    dateTo,
    id,
    mapLink,
    moreInformationLink,
    slug,
    tiles = [],
    title,
  } = data;
  return {
    contentfulId: contentful_id,
    copy: get(data, 'copy.copy', ''),
    endDate: dateTo,
    id,
    imagesSlider: (get(data, 'gallery', []) || []).map(mapImage),
    lead: get(data, 'lead.lead', ''),
    mapLink,
    moreInformationLink,
    slug,
    startDate: dateFrom,
    tiles: getDataTiles({
      contentful_id: `tiles-${contentful_id}`, // eslint-disable-line camelcase
      tiles: tiles || [],
    }),
    title,
  };
}
