import PropTypes from 'prop-types';
import React, { Component } from 'react';
import dateFormat from 'dateformat';

import './press-link.scss';

const onClick = function onToggleClick(ev) {
  ev.preventDefault();
  ev.currentTarget.parentElement.classList.toggle('is-animating');
};

class PressLink extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;
    this.state = {
      isVisible: false,
    };

    this.makeVisible = this.makeVisible.bind(this);
  }

  componentDidMount() {
    this.timeout = setTimeout(this.makeVisible, 100);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  makeVisible() {
    this.setState({
      isVisible: true,
    });
  }

  render() {
    const {
      date,
      index,
      link = '#',
      magazineTitle,
      title,
    } = this.props;

    const {
      isVisible,
    } = this.state;

    const classes = [
      'press__link',
      index === 0 ? 'press__link--group' : null,
      isVisible ? 'is-visible' : null,
    ];

    return (
      <li className={classes.join(' ')}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {index > 0 ? (
            <span className="press__link__column press__link__column--year" />
          ) : (
            <time dateTime={date} className="press__link__column press__link__column--year">
              {date.substr(0, 4)}
            </time>
          )}
          <time dateTime={date} className="press__link__column press__link__column--month">
            {dateFormat(date, 'mmmm')}
          </time>
          <div className="press__link__column press__link__column--editor">
            <span className="press__link__content">
              {magazineTitle}
            </span>
            <span className="press__link__content">
              {magazineTitle}
            </span>
            <button onClick={onClick} className="press__link__toggle" type="button">
              Toggle
            </button>
          </div>
          <div className="press__link__column press__link__column--title">
            <span className="press__link__content">
              {title}
            </span>
            <span className="press__link__content">
              {title}
            </span>
            <button onClick={onClick} className="press__link__toggle" type="button">
              Toggle
            </button>
          </div>
        </a>
      </li>
    );
  }
}

PressLink.propTypes = {
  date: PropTypes.string,
  index: PropTypes.number,
  link: PropTypes.string,
  magazineTitle: PropTypes.string,
  title: PropTypes.string,
};

PressLink.defaultProps = {
  date: '',
  index: 1,
  link: '#',
  magazineTitle: '',
  title: '',
};

export default PressLink;
